import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const breakpoints = ["0em", "100em"];

const theme = extendTheme({
  styles: {
    global: (props) => ({
      "html, body": {
        background: mode("#D0B5B8", "black"),
        color: mode("black", "white"),
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold", // You can customize the base styles
        bg: "hotpink",
        color: "white",
        _hover: {
          bg: "darkorchid",
        },
      },
      sizes: {
        md: {
          h: "48px",
          fontSize: "lg",
          px: "24px",
        },
      },
      bg: "hotpink",
      color: "white",
      _hover: {
        bg: "darkorchid",
      },
      variants: {
        primary: {
          bg: "hotpink",
          color: "white",
          _hover: {
            bg: "darkorchid",
          },
        },
        outline: {
          border: '2px',
          borderColor: "white",
          color: "white",
          fontSize: '18px',
          transition: 'background 1s',
          _hover: {
            borderColor: "#650000",
            bg: "#650000",
            color: "white",
          },
        },
      },
    },
  },
  fonts: {
    body: "inter, sans-serif",
    heading: "inter, serif",
    mono: "inter, monospace",
  },
  fontSizes: {
    "05": "",
    "9xl": "140px",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
});

export default theme;
